import { Injectable } from '@angular/core';
import { Apollo, ApolloBase, gql } from 'apollo-angular';

import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Article, RC_CMS_APOLLO_CLIENT_NAME } from '../types';

interface ArticlesList {
  Articles: {
    docs: Array<Article>;
    totalDocs: number;
    page: number;
    totalPages: number;
    hasNextPage: number;
    hasPrevPage: number;
    nextPage: number;
    prevPage: number;
  };
}

@Injectable()
export class RcCmsArticlesService {
  private apollo: ApolloBase;

  constructor(private apolloProvider: Apollo) {
    this.apollo = this.apolloProvider.use(RC_CMS_APOLLO_CLIENT_NAME);
  }

  // Return fully resolved media URLs from the CMS host based on provided relative media path
  public resolveMediaUrl(mediaFilePath: string): string {
    return `${environment.cms.mediaUrl}${mediaFilePath}`;
  }

  public getArticles(page: number = 1): Observable<Array<Article>> {
    return this.apollo
      .query<ArticlesList, { page: number }>({
        query: gql`
          query ArticlesList($page: Int = 1) {
            Articles(page: $page) {
              docs {
                title
                slug
                publishedAt
                populatedAuthor {
                  name
                }
                hero {
                  richText
                  media {
                    url
                    alt
                  }
                }
                categories {
                  title
                }
                meta {
                  title
                  description
                  image {
                    alt
                    url
                  }
                }
              }
              totalDocs
              page
              totalPages
              hasNextPage
              hasPrevPage
              nextPage
              prevPage
            }
          }
        `,
        variables: { page },
      })
      .pipe(map((result) => result?.data?.Articles?.docs || []));
  }

  public getArticleBySlug(slug: string): Observable<Article> {
    return this.apollo
      .query<{ Articles: { docs: Array<Article> } }, { slug: string }>({
        query: gql`
          query Article($slug: String) {
            Articles(where: { slug: { equals: $slug } }) {
              docs {
                title
                slug
                meta {
                  title
                  description
                  image {
                    alt
                    url
                  }
                }
                hero {
                  richText
                  media {
                    url
                    alt
                  }
                }
                populatedAuthor {
                  name
                }
                publishedAt
                categories {
                  title
                }
                content {
                  __typename
                  ... on Content {
                    richText
                  }
                  ... on MediaBlock {
                    media {
                      url
                      alt
                      caption
                    }
                  }
                  ... on Cta {
                    richText
                    links {
                      link {
                        label
                        url
                        appearance
                      }
                    }
                  }
                }
                relatedArticles {
                  title
                  slug
                  meta {
                    title
                    description
                    image {
                      alt
                      url
                    }
                  }
                  hero {
                    richText
                    media {
                      url
                      alt
                    }
                  }
                  populatedAuthor {
                    name
                  }
                  publishedAt
                  categories {
                    title
                  }
                }
              }
            }
          }
        `,
        variables: {
          slug,
        },
      })
      .pipe(
        map((result) => {
          const articleBySlug = result?.data?.Articles?.docs[0] ?? null;

          if (articleBySlug === null)
            throw new Error(`Could not find Article '${slug}'`);

          return articleBySlug;
        }),
      );
  }
}
